import React from 'react'
import Select, { SingleValue } from 'react-select'

import { FormLabel } from '@chakra-ui/react'
import * as R from 'ramda'

import {
  allAttributeOptions,
  genericAttributeOptions,
} from '../constants/attribute'
import { BadgeConfigActionType, useBadgeConfig } from '../context/BadgeConfig'
import { EntityType } from '../types/entity'
import { Option } from '../types/select'

const AttributeSelect = () => {
  const [{ entity, attribute }, dispatch] = useBadgeConfig()

  const attributeOptions =
    entity?.type === EntityType.Character
      ? genericAttributeOptions
      : allAttributeOptions

  const attributeValue = R.find(
    R.propEq('value', attribute),
    allAttributeOptions
  )

  const handleChange = (value: SingleValue<Option>) => {
    if (value) {
      dispatch({
        type: BadgeConfigActionType.SET_ATTRIBUTE,
        payload: value.value,
      })
    }
  }

  return (
    <>
      <FormLabel htmlFor="badge-attribute" margin="0" color="whiteAlpha.900">
        Attribute
      </FormLabel>
      <Select
        id="badge-attribute"
        styles={{
          control: (base) => ({ ...base, width: 180 }),
        }}
        value={attributeValue}
        onChange={handleChange}
        options={attributeOptions}
        isSearchable={false}
      />
    </>
  )
}

export default AttributeSelect
