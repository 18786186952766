import useSWR from 'swr'

import useBadgeUrl from '../hooks/useBadgeUrl'

import keepPrevious, {
  KeepPreviousSWRResponse,
} from './middleware/keepPrevious'

const useBadgeSrcSWR = () => {
  const url = useBadgeUrl()

  return useSWR(url, (url: string) => fetch(url).then((res) => res.text()), {
    use: [keepPrevious],
  }) as KeepPreviousSWRResponse<string>
}

export default useBadgeSrcSWR
