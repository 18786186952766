import qs from 'qs'

import { BadgeStyle } from '../constants/badge'
import { useBadgeConfig } from '../context/BadgeConfig'

const useBadgeUrl = () => {
  const [{ entity, attribute, style, hideLogo }] = useBadgeConfig()

  if (!entity) return null
  let url = 'https://img.eveshields.io/badge/'
  url += `${entity.type}/${entity.id}/${attribute}`
  url += qs.stringify(
    {
      hideLogo: hideLogo || undefined,
      style: style === BadgeStyle.Flat ? undefined : style,
    },
    {
      addQueryPrefix: true,
    }
  )

  return url
}

export default useBadgeUrl
