import React from 'react'
import ReactDOM from 'react-dom'

import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { SWRConfig } from 'swr'

import App from './App'

import './index.css'

Sentry.init({
  dsn: 'https://02f365d7a9ca44288d3fef38bde85fa9@o438023.ingest.sentry.io/5999458',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['img.eveshields.io'],
    }),
  ],
  tracesSampleRate: 0.2,
})

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          dedupingInterval: 60_000,
        }}
      >
        <App />
      </SWRConfig>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
