import { BadgeFormat } from '../constants/badge'
import { useBadgeConfig } from '../context/BadgeConfig'

import useBadgeUrl from './useBadgeUrl'

const useBadgeFormatter = () => {
  const [{ entity, attribute }] = useBadgeConfig()
  const url = useBadgeUrl()

  const label = `${entity?.name}: ${attribute}`

  return (format: BadgeFormat) => {
    switch (format) {
      case BadgeFormat.URL:
        return url
      case BadgeFormat.RestructuredText:
        return `.. image:: ${url}\n  :alt: ${label}`
      case BadgeFormat.Markdown:
        return `![${label}](${url})`
      case BadgeFormat.ASCII:
        return `image:${url}[${label}]`
      case BadgeFormat.HTML:
        return `<img alt="${label}" src="${url}">`
    }
  }
}

export default useBadgeFormatter
