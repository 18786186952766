import React from 'react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ToastPositionWithLogical,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import copyToClipboard from 'copy-to-clipboard'

import useBadgeSrcSWR from '../api/useBadgeSrcSWR'
import { BadgeFormat } from '../constants/badge'
import useBadgeFormatter from '../hooks/useBadgeFormatter'

const Copy = () => {
  const { data: badgeSrc } = useBadgeSrcSWR()
  const toast = useToast()
  const badgeFormatter = useBadgeFormatter()

  const toastID = 'clipboard-toast'
  const toastPosition = useBreakpointValue<ToastPositionWithLogical>({
    base: 'top',
    md: 'bottom-right',
  })

  const handleCopyClick = async (format: BadgeFormat) => {
    const formattedBadge = badgeFormatter(format)

    const didCopy = copyToClipboard(formattedBadge || '')

    if (didCopy && !toast.isActive(toastID)) {
      toast({
        id: toastID,
        position: toastPosition,
        duration: 1250,
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Copied to clipboard!
          </Box>
        ),
      })
    }
  }

  return (
    <ButtonGroup isDisabled={!badgeSrc} isAttached>
      <Button onClick={() => handleCopyClick(BadgeFormat.URL)}>
        Copy Badge URL
      </Button>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="copy options"
          icon={<ChevronDownIcon />}
        />
        <MenuList>
          <MenuItem onClick={() => handleCopyClick(BadgeFormat.Markdown)}>
            Copy Markdown
          </MenuItem>
          <MenuItem
            onClick={() => handleCopyClick(BadgeFormat.RestructuredText)}
          >
            Copy reStructuredText
          </MenuItem>
          <MenuItem onClick={() => handleCopyClick(BadgeFormat.ASCII)}>
            Copy AsciiDoc
          </MenuItem>
          <MenuItem onClick={() => handleCopyClick(BadgeFormat.HTML)}>
            Copy HTML
          </MenuItem>
        </MenuList>
      </Menu>
    </ButtonGroup>
  )
}

export default Copy
