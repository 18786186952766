export enum EntityType {
  Alliance = 'alliance',
  Corporation = 'corporation',
  Character = 'character',
}

export interface Entity {
  id: number
  name: string
  type: EntityType
  image: string
}
