import React from 'react'
import Select, { SingleValue } from 'react-select'

import { FormLabel, Switch } from '@chakra-ui/react'
import * as R from 'ramda'

import { badgeStyleOptions } from '../constants/badge'
import { BadgeConfigActionType, useBadgeConfig } from '../context/BadgeConfig'
import { EntityType } from '../types/entity'
import { Option } from '../types/select'

const AdvancedOptions = () => {
  const [{ style, hideLogo, entity }, dispatch] = useBadgeConfig()

  const imageToggleLabel =
    entity?.type === EntityType.Character ? 'Hide portrait' : 'Hide logo'

  const styleValue = R.find(R.propEq('value', style), badgeStyleOptions)

  const handleChangeStyle = (value: SingleValue<Option>) => {
    if (value) {
      dispatch({
        type: BadgeConfigActionType.SET_STYLE,
        payload: value.value,
      })
    }
  }

  const handleToggleHideLogo = () =>
    dispatch({
      type: BadgeConfigActionType.TOGGLE_HIDE_LOGO,
    })

  return (
    <>
      <FormLabel htmlFor="badge-style" margin="0" color="whiteAlpha.900">
        Style
      </FormLabel>
      <Select
        id="badge-style"
        styles={{
          control: (base) => ({ ...base, width: 180 }),
          singleValue: (base) => ({ ...base, textTransform: 'capitalize' }),
          option: (base) => ({
            ...base,
            textTransform: 'capitalize',
          }),
        }}
        value={styleValue}
        options={badgeStyleOptions}
        onChange={handleChangeStyle}
        isSearchable={false}
      />
      <FormLabel htmlFor="hide-logo" margin="0" color="whiteAlpha.900">
        {imageToggleLabel}
      </FormLabel>
      <Switch
        id="hide-logo"
        isChecked={hideLogo}
        onChange={handleToggleHideLogo}
      />
    </>
  )
}

export default AdvancedOptions
