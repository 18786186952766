import React from 'react'

import { useBreakpointValue } from '@chakra-ui/react'

import backgroundImage from '../assets/images/background.png'
import backgroundVideo from '../assets/videos/background.webm'

const Background = React.memo(() => {
  const height = useBreakpointValue({ base: '1080px', sm: '100vh' })
  return (
    <video
      autoPlay
      muted
      loop
      style={{
        height,
        /* average color of video */
        background: '#2a3044',
        left: 0,
        objectFit: 'cover',
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: -1,
      }}
      poster={backgroundImage}
    >
      <source src={backgroundVideo} type="video/webm" />
    </video>
  )
})

export default Background
