import * as R from 'ramda'
import useSWR from 'swr'

import { Entity, EntityType } from '../types/entity'

import { fetcher as fetch } from '.'
import keepPrevious, {
  KeepPreviousSWRResponse,
} from './middleware/keepPrevious'

const fetcher = (url: string) =>
  fetch<Entity[]>(url).then((data) =>
    data.filter((result) => R.values(EntityType).includes(result.type))
  )

const useSearchSWR = (query: string) => {
  const url =
    query?.length > 2 ? `https://zkillboard.com/autocomplete/${query}` : null

  const options = { use: [keepPrevious] }

  return useSWR(url, fetcher, options) as KeepPreviousSWRResponse<Entity[]>
}

export default useSearchSWR
