import React from 'react'

import { Box, Center, Grid, VStack } from '@chakra-ui/react'

import AdvancedOptions from './components/AdvancedOptions'
import AttributeSelect from './components/AttributeSelect'
import Background from './components/Background'
import Badge from './components/Badge'
import Byline from './components/Byline'
import Copy from './components/Copy'
import EntitySearch from './components/EntitySearch'
import { BadgeConfigProvider } from './context/BadgeConfig'

function App() {
  return (
    <>
      <Background />

      <Byline />
      <Center padding={[24, 0]} height={['', '100vh']}>
        <VStack>
          <BadgeConfigProvider>
            <Box paddingBottom="4">
              <EntitySearch />
            </Box>
            <Grid
              width="100%"
              gridTemplateColumns={['1fr', '1fr 1fr']}
              justifyItems="center"
              alignItems="center"
              gridRowGap="2"
              background="whiteAlpha.300"
              py="4"
            >
              <AttributeSelect />
              <AdvancedOptions />
            </Grid>
            <Box>
              <VStack marginTop="8" spacing="8">
                <Badge />
                <Copy />
              </VStack>
            </Box>
          </BadgeConfigProvider>
        </VStack>
      </Center>
    </>
  )
}

export default App
