import React from 'react'

import { HStack, Image, Link, Text } from '@chakra-ui/react'

import localIsPrimaryImage from '../assets/images/localisprimary.png'

const Footer = React.memo(() => (
  <Link href="https://www.localisprimary.com" target="_blank">
    <HStack
      userSelect="none"
      position="fixed"
      top="2"
      right="2"
      spacing="1"
      aria-label="Coded with love by Local Is Primary"
    >
      <Text>🧑‍💻</Text>
      <Text>❤</Text>
      <Image src={localIsPrimaryImage} display="inline" width="16px" />
    </HStack>
  </Link>
))

export default Footer
