import React from 'react'
import Select, { SingleValue } from 'react-select'

import { useBreakpointValue } from '@chakra-ui/react'
import mobile from 'is-mobile'
import useDebounce from 'use-debounce/esm/useDebounce'

import useSearchSWR from '../../api/useSearchSWR'
import {
  BadgeConfigActionType,
  useBadgeConfig,
} from '../../context/BadgeConfig'
import { Entity } from '../../types/entity'

import Option from './Option'

const EntitySearch = () => {
  const [{ entity }, dispatch] = useBadgeConfig()
  const [query, setQuery] = React.useState('')
  const [debouncedQuery] = useDebounce(query, 500)

  const selectWidth = useBreakpointValue({ base: 300, sm: 400 })

  const { data: options, isLagging } = useSearchSWR(debouncedQuery)

  const isLoading = (!options || isLagging) && debouncedQuery.length > 2

  const handleChangeEntity = (value: SingleValue<Entity>) => {
    if (value) {
      dispatch({
        type: BadgeConfigActionType.SET_ENTITY,
        payload: value,
      })
    }
  }

  const isMobile = React.useMemo(() => mobile(), [])

  React.useEffect(() => {
    if (entity) {
      document.title = `${entity.name
        .replace(/\(Closed\)$/, '')
        .trim()} | Eveshields.io`
    }
  }, [entity])

  return (
    <Select
      styles={{
        control: (base) => ({ ...base, width: selectWidth }),
      }}
      autoFocus={!isMobile}
      isLoading={isLoading}
      isOptionSelected={(option) => option.id === entity?.id}
      value={entity}
      onChange={handleChangeEntity}
      options={options}
      onInputChange={setQuery}
      getOptionLabel={(opt) => opt.name}
      placeholder="Corporation, alliance, character..."
      formatOptionLabel={Option}
      loadingMessage={() => null}
      noOptionsMessage={({ inputValue }) =>
        inputValue && options ? 'Nothing found' : null
      }
      components={{
        DropdownIndicator: null,
      }}
    />
  )
}

export default EntitySearch
