import React from 'react'

import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react'

import { Entity } from '../../types/entity'

const Option: React.FC<Entity> = ({ name, type, image }) => (
  <Flex alignItems="center">
    <Box flex="1">
      <HStack>
        <Image src={`https://images.evetech.net/${image}`} />
        <Text>{name.replace(/\(Closed\)$/, '').trim()}</Text>
      </HStack>
    </Box>
    <Text as="i">({type})</Text>
  </Flex>
)

export default Option
