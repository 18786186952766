import React from 'react'

import { Box, HStack, Image, Spinner } from '@chakra-ui/react'
import qs from 'qs'

import useBadgeSrcSWR from '../api/useBadgeSrcSWR'
import { useBadgeConfig } from '../context/BadgeConfig'
import useBadgeUrl from '../hooks/useBadgeUrl'

const Badge = () => {
  const [{ style }] = useBadgeConfig()
  const { data: badgeSrc, isLagging } = useBadgeSrcSWR()
  const url = useBadgeUrl()

  const isLoading = (url && !badgeSrc) || isLagging
  const fallbackURL = `https://img.shields.io/badge/preview-missing parameter-lightgray?${qs.stringify(
    { style: style }
  )}`

  return (
    <HStack position="relative" height="12px">
      {badgeSrc ? (
        <Box
          dangerouslySetInnerHTML={{ __html: badgeSrc }}
          aria-label="badge"
        />
      ) : (
        <Image src={fallbackURL} alt="badge" />
      )}
      {isLoading && (
        <Spinner
          color="whiteAlpha.900"
          size="sm"
          position="absolute"
          right="-6"
        />
      )}
    </HStack>
  )
}

export default Badge
