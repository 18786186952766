import React from 'react'
import { SingleValue } from 'react-select'

import produce from 'immer'

import { Attribute, genericAttributes } from '../constants/attribute'
import { BadgeStyle } from '../constants/badge'
import { Entity, EntityType } from '../types/entity'

interface BadgeConfig {
  entity: SingleValue<Entity>
  attribute: string
  style: string
  hideLogo: boolean
}

export enum BadgeConfigActionType {
  SET_ENTITY = 'SET_ENTITY',
  SET_ATTRIBUTE = 'SET_ATTRIBUTE',
  SET_STYLE = 'SET_STYLE',
  TOGGLE_HIDE_LOGO = 'TOGGLE_HIDE_LOGO',
}

type BadgeConfigAction =
  | { type: BadgeConfigActionType.SET_ENTITY; payload: Entity }
  | { type: BadgeConfigActionType.SET_ATTRIBUTE; payload: string }
  | { type: BadgeConfigActionType.SET_STYLE; payload: string }
  | { type: BadgeConfigActionType.TOGGLE_HIDE_LOGO }

const initialBadgeConfig: BadgeConfig = {
  entity: null,
  attribute: Attribute.ISK_DESTROYED,
  style: BadgeStyle.Flat,
  hideLogo: false,
}

const configReducer = produce(
  (state: BadgeConfig, action: BadgeConfigAction) => {
    switch (action.type) {
      case BadgeConfigActionType.SET_ENTITY:
        if (state.entity?.type !== action.payload.type) {
          // Reset attribute if it doesn't apply to current type
          if (action.payload.type === EntityType.Character) {
            if (!(state.attribute in genericAttributes)) {
              state.attribute = Attribute.ISK_DESTROYED
            }
          }
        }
        state.entity = action.payload
        return
      case BadgeConfigActionType.SET_STYLE:
        state.style = action.payload
        return
      case BadgeConfigActionType.SET_ATTRIBUTE:
        state.attribute = action.payload
        return
      case BadgeConfigActionType.TOGGLE_HIDE_LOGO:
        state.hideLogo = !state.hideLogo
        return
      default:
        throw new Error('Invalid action type')
    }
  }
)

const BadgeConfigContext = React.createContext<
  [BadgeConfig, React.Dispatch<BadgeConfigAction>]
>([initialBadgeConfig, () => {}])

export const useBadgeConfig = () => React.useContext(BadgeConfigContext)

export const BadgeConfigProvider: React.FC = ({ children }) => {
  const reducer = React.useReducer(configReducer, initialBadgeConfig)

  return (
    <BadgeConfigContext.Provider value={reducer}>
      {children}
    </BadgeConfigContext.Provider>
  )
}
