import * as R from 'ramda'

export enum Attribute {
  SHIPS_LOST = 'shipsLost',
  SHIPS_DESTROYED = 'shipsDestroyed',
  ISK_LOST = 'iskLost',
  ISK_DESTROYED = 'iskDestroyed',
  SOLO_KILLS = 'soloKills',
  SOLO_LOSSES = 'soloLosses',
  THREAT = 'threat',
  TOP_PILOT = 'topPilot',
  ACTIVE_PILOTS = 'activePilots',
  PILOTS = 'pilots',
}

export const genericAttributes = Object.freeze({
  [Attribute.ISK_DESTROYED]: 'ISK Destroyed',
  [Attribute.ISK_LOST]: 'ISK Lost',
  [Attribute.SHIPS_DESTROYED]: 'Ships Destroyed',
  [Attribute.SHIPS_LOST]: 'Ships Lost',
  [Attribute.SOLO_KILLS]: 'Solo Kills',
  [Attribute.SOLO_LOSSES]: 'Solo Losses',
  [Attribute.THREAT]: 'Threat',
})

export const groupAttributes = Object.freeze({
  [Attribute.TOP_PILOT]: 'Top Pilot',
  [Attribute.ACTIVE_PILOTS]: 'Active Pilots',
  [Attribute.PILOTS]: 'Pilots',
})

export const allAttributes = Object.freeze({
  ...genericAttributes,
  ...groupAttributes,
})

const createOptions = (options: Record<string, string>) =>
  Object.freeze(R.toPairs(options).map(([value, label]) => ({ value, label })))

export const genericAttributeOptions = createOptions(genericAttributes)
export const groupAttributeOptions = createOptions(groupAttributes)
export const allAttributeOptions = createOptions(allAttributes)
