import * as R from 'ramda'

export enum BadgeStyle {
  Plastic = 'plastic',
  Flat = 'flat',
  FlatSquare = 'flat-square',
  ForTheBadge = 'for-the-badge',
  Social = 'social',
}

export enum BadgeFormat {
  URL,
  Markdown,
  RestructuredText,
  ASCII,
  HTML,
}

export const badgeStyleOptions = R.values(BadgeStyle).map((style) => ({
  value: style,
  label: style.replace(/-/g, ' '),
}))
